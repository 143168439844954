import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: rgba(10, 21, 25, 0.9);
  color: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(10px);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const Logo = styled(Link)`
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    background-color: rgba(10, 21, 25, 0.95);
    z-index: 1;
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    transition: all 0.3s ease-in-out;
  }

  @media screen and (min-width: 769px) {
    justify-content: flex-end;
  }
`;

const NavLink = styled.li`
  margin: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 1.5rem;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1a1a1a;
    color: #f0a500;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: block;
    z-index: 2;
  }
`;

const HamburgerLine = styled.div`
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
  transition: all 0.3s ease;
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && menuRef.current && !menuRef.current.contains(event.target) && !event.target.closest(HamburgerIcon)) {
        setIsOpen(false);
      }
    };
  
    window.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <Nav>
        <Logo to="/">El Sitio</Logo>
        <HamburgerIcon onClick={toggleMenu}>
          <HamburgerLine />
          <HamburgerLine />
          <HamburgerLine />
        </HamburgerIcon>
        <NavLinks isOpen={isOpen} ref={menuRef}>
          <NavLink>
            <StyledLink to="/meniu" onClick={toggleMenu}>
              Meniu
            </StyledLink>
          </NavLink>
          <NavLink>
            <StyledLink to="/contact" onClick={toggleMenu}>
              Contact
            </StyledLink>
          </NavLink>
          <NavLink>
            <StyledLink to="/" onClick={toggleMenu}>
              Pagina Principală
            </StyledLink>
          </NavLink>
        </NavLinks>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
