import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
// import menuData from '../menu.json';
import FloatingScrollButton from '../components/FloatingScrollButton';

const MenuContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 1rem;
    width: 90vw;
  }
`;

const MenuHeading = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const MenuHeaderBackground = styled.div`
  background: url('/assets/site-images/hero-image.jpeg');
  background-color: #0a1519;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 15rem 0 1rem 0;
  @media (max-width: 768px) {
    padding-top: 10rem;
  }
`;

const MenuItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1.5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MenuItem = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  max-height: 120px;
  @media (max-width: 768px) {
    max-height: 100px;
  }
`;

const MenuItemImg = styled.img`
  width: 140px;
  height: 120px;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 120px;
    height: 100px;
  }
`;

const MenuItemContent = styled.div`
  padding: 0.5rem;
  margin: 0.5rem;
  border-left: 1px dotted black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const MenuItemText = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItemTitle = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.3rem;
  margin-top: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 0.9em;
    margin: 0;
  }
`;

const MenuItemWeight = styled.span`
  font-size: 0.8rem;
  color: #666;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const MenuItemDescription = styled.p`
  font-size: 0.8rem;
  color: #555;
  margin-bottom: 0.3rem;
  margin-top: 0rem;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const MenuItemPrice = styled.div`
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  align-self: flex-end;
  position: absolute;
  bottom: 0.3rem;
  right: 0.5rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const MenuTypeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  border-bottom: 2px solid #F86F03;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const MenuTypeIcon = styled.i`
  font-size: 2.5rem;
  margin-right: 0.4rem;
  color: #F86F03;
  @media (max-width: 768px) {
    font-size: 1.7rem;
  }
`;

const MenuTypeTitle = styled.h2`
  font-size: 1.6rem;
  margin: 1rem 0;
  color: #F86F03;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const fallbackImageUrl = '/assets/site-images/logo.png';

const Menu = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    fetch('/assets/data/menu.json')
      .then(response => response.json())
      .then(data => setMenuData(data))
      .catch(error => console.error('Eroare în încărcarea fișierului JSON:', error));
  }, []);

  if (!menuData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MenuHeaderBackground>
        <MenuHeading>Meniu Mâncare</MenuHeading>
      </MenuHeaderBackground>
      <MenuContainer>
        {Object.entries(menuData).map(([type, foods]) => (
          <div key={type}>
            <MenuTypeHeader>
              <MenuTypeIcon className="material-icons">{foods.icon}</MenuTypeIcon>
              <MenuTypeTitle>{type.toUpperCase()}</MenuTypeTitle>
            </MenuTypeHeader>
            <MenuItemWrapper>
              {foods.items.map((item, index) => (
                <MenuItem key={index}>
                  <LazyLoad height={120} offset={100} once>
                    <MenuItemImg src={item.imageUrl || fallbackImageUrl} alt={item.name} />
                  </LazyLoad>
                  <MenuItemContent>
                    <MenuItemText>
                      <MenuItemTitle>{item.name} <MenuItemWeight>({item.weight})</MenuItemWeight></MenuItemTitle>
                      {item.description && <MenuItemDescription>{item.description}</MenuItemDescription>}
                    </MenuItemText>
                    <MenuItemPrice>{item.price} lei</MenuItemPrice>
                  </MenuItemContent>
                </MenuItem>
              ))}
            </MenuItemWrapper>
          </div>
        ))}
        <FloatingScrollButton />
      </MenuContainer>
    </>
  );
};

export default Menu;
