import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ScrollButton = styled.button`
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  background-color: #f0a500;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 10px;
  cursor: pointer;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, visibility 0.3s;

  @media (max-width: 768px) {
    bottom: 4rem;
    right: 1rem;
  }

  &:hover {
    background-color: #d48f00;
  }
`;

const FloatingScrollButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <ScrollButton show={showButton} onClick={scrollToTop}>
      <span className="material-icons">arrow_upward</span>
    </ScrollButton>
  );
};

export default FloatingScrollButton;
