import {React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Menu from './pages/Menu';
import Contact from './pages/Contact';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex-grow: 1;
`;
const ScrollToTopOnRouteChange = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

const App = () => {
    return (
        <Router>
            <AppContainer>
                <ScrollToTopOnRouteChange />
                <Header />
                <Content>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/meniu" element={<Menu />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </Content>
                <Footer />
            </AppContainer>
        </Router>
    );
};

export default App;
