import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #0a1519;
  color: #fff;
  padding: 1rem;
  text-align: center;
`;

const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContactInfo = styled.div`
  flex: 1 1 300px;
`;

const OperatingHours = styled.div`
  flex: 1 1 300px;
`;

const NavigationLinks = styled.div`
  flex: 1 1 300px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;
  display: block;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <ContactInfo>
                    <h3>Contact</h3>
                    <p>Email: elsitiogames@gmail.com</p>
                    <p>
                        Tel:{' '}
                        <a href="tel:+40728434617" style={{ textDecoration: 'none', color: '#fff' }}>
                            +40 728 434 617
                        </a>
                    </p>
                    <div>
                        <Link href="https://www.facebook.com/elsitio100" target="_blank">Facebook: @elsitio100</Link>
                        <Link href="https://www.instagram.com/el_sitio22/" target="_blank">Instagram: @el_sitio22</Link>
                    </div>

                </ContactInfo>
                <OperatingHours>
                    <h3>Adresă</h3>
                    <p>
                        <Link href="https://maps.google.com?q=El+Sitio,+Cilieni+237075" target="_blank" rel="noopener noreferrer">
                            Neagoe Basarab nr.7, Cilieni, Olt
                        </Link>
                    </p>
                    <p>
                        <Link href="https://maps.google.com?q=El+Sitio,+Cilieni+237075" target="_blank" rel="noopener noreferrer">
                            Vizitați în Google Maps
                        </Link>
                    </p>
                    <p>Deschis: non-stop</p>
                </OperatingHours>


                <NavigationLinks>
                    <h3>Navigare</h3>
                    <Link href="/meniu">Meniu</Link>
                    <Link href="/contact">Contact</Link>
                    <Link href="/">Pagina Principală</Link>
                </NavigationLinks>
            </FooterContent>
            <p>&copy; 2024 ElSitio. Toate drepturile rezervate.</p>
        </FooterContainer>
    );
};

export default Footer;
