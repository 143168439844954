import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Announcement from '../components/Announcement';

const ContactContainer = styled.div`
  padding: 2rem;
  width: 80vw;
  margin: 0 auto;
  margin-top: 50px;
`;

const ContactHeading = styled.h2`
  font-size: 2rem;
  color: #f57c00;
  margin-bottom: 1.5rem;
`;

const ContactContent = styled.div`
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;

  h3 {
    font-size: 1.5rem;
    color: #f57c00;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 2rem;
`;

const ContactLink = styled.a`
  color: #f57c00;
  text-decoration: none;
  margin-right: 1rem;
`;

const AddressLink = styled(Link)`
  color: #f57c00;
  text-decoration: none;
`;

const MapContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 30%;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    padding-top: 56.25%;
  }
`;

const MapIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const Contact = () => {
  return (
    <ContactContainer>
      <ContactHeading>Contactează-ne</ContactHeading>
      <Announcement />
      <ContactInfo>
        <h3>Informații de Contact:</h3>
        <p>
          Telefon: <ContactLink href="tel:+40728434617">+40 728 434 617</ContactLink>
        </p>
        <p>
          Facebook: <ContactLink href="https://www.facebook.com/elsitio100" target="_blank" rel="noopener noreferrer">@elsitio100</ContactLink>
        </p>
        <p>
          Instagram: <ContactLink href="https://www.instagram.com/el_sitio22/" target="_blank" rel="noopener noreferrer">@el_sitio22</ContactLink>
        </p>
      </ContactInfo>
      <h3>Adresă:</h3>
      <p>
        <AddressLink to="https://maps.google.com?q=El+Sitio,+Cilieni+237075" target="_blank" rel="noopener noreferrer">
          Neagoe Basarab nr. 7, Cilieni, Olt
        </AddressLink>
      </p>
      <MapContainer>
        <MapIframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2874.6106123454692!2d24.608062076407357!3d43.90533083608698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ac5862860e1823%3A0x1caa69dd9ca52f36!2sEl%20Sitio!5e0!3m2!1sro!2sro!4v1715269241306!5m2!1sro!2sro"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></MapIframe>
      </MapContainer>
    </ContactContainer>
  );
};

export default Contact;
