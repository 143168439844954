import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import Announcement from '../components/Announcement';

const BackgroundImage = styled.div`
  width: 100%;
  height: 22.5rem;
  background-color: #0a1519;
  background-image: url('/assets/site-images/hero-image.jpeg');
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 768px) {
    height: 15rem;
  }
`;

const HomeContainer = styled.div`
  margin-top: -1px;
`;

const HomeContent = styled.div`
  text-align: center;
  width: 80vw;
  margin: 0 auto;
  padding: 1rem;
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Subheading = styled.div`
  font-size: 1.5rem;
  color: #000;
  margin-bottom: 2rem;
`;

const LinksContainer = styled.div`
  margin: 2rem 0;
`;

const glow = keyframes`
  from {
    box-shadow: 0 0 10px 0px rgba(255, 126, 95, 0.7);
  }
  to {
    box-shadow: 0 0 20px 5px rgba(255, 126, 95, 0.7);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.6rem 1rem;
  background-image: linear-gradient(#ffdf93, #ff7e5f);
  border-radius: 5rem;
  animation: ${glow} 0.8s ease-in-out infinite alternate;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
`;

const MenuItem = styled.div`
  flex-basis: 25rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 900px) {
    flex-basis: 20rem;
  }
`;

const MenuItemImg = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

const MenuItemContent = styled.div`
  padding: 1rem;
  text-align: left;
`;

const MenuItemTitle = styled.h3`
  font-size: 1rem;
  color: #333;
  margin: 0.5rem 0;
`;

const MenuItemWeight = styled.span`
  font-size: 0.7rem;
  color: #666;
  font-weight: 500;
`;

const MenuItemDescription = styled.p`
  font-size: 0.8rem;
  color: #555;
  margin: 0.5rem 0;
`;

const MenuItemPrice = styled.div`
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
`;

const items = [
  {
    name: "Burger de vită + cartofi wedges",
    weight: "400g+200g",
    description: "chiflă, roșii, carne de vită, salată verde, sos de usturoi, cheddar, bacon, cartofi",
    price: "42",
    imageUrl: "/assets/images/burgeri-si-coaste/burger-vita.png"
  },
  {
    "name": "Papanași",
    "weight": "280g",
    "description": "făină, zahăr, ouă, brânză de vaci, gem",
    "price": "27",
    "imageUrl": "/assets/images/desert/papanasi.png"
  },
  {
    name: "Coaste la cuptor + cartofi wedges",
    weight: "400g+200g",
    description: "coaste de porc, sos bbq, cartofi",
    price: "40",
    imageUrl: "/assets/images/burgeri-si-coaste/coaste.png"
  },
  {
    "name": "Clătite",
    "weight": "180g",
    "description": "aluat de clătite, topping",
    "price": "15",
    "imageUrl": "/assets/images/desert/clatite.png"
  }
];

const Home = () => {
  return (
    <HomeContainer>
      <BackgroundImage />
      <HomeContent>
        <Heading>
          Contact: {' '}
          <a href="tel:+40728434617" style={{ textDecoration: 'none', color: '#000' }}>
            0728 434 617
          </a>
        </Heading>
        <LinksContainer>
          <StyledLink to="/meniu">Meniu Complet</StyledLink>
        </LinksContainer>
        <Subheading>Recomandările noastre</Subheading>

        <MenuItemWrapper>
          {items.map((item, index) => (
            <MenuItem key={index}>
              <MenuItemImg src={item.imageUrl} alt={item.name} />
              <MenuItemContent>
                <MenuItemTitle>{item.name} <MenuItemWeight>({item.weight})</MenuItemWeight></MenuItemTitle>
                {item.description && <MenuItemDescription>{item.description}</MenuItemDescription>}
                <MenuItemPrice>{item.price} lei</MenuItemPrice>
              </MenuItemContent>
            </MenuItem>
          ))}
        </MenuItemWrapper>

        <LinksContainer>
          <StyledLink to="/meniu">Meniu Complet</StyledLink>
        </LinksContainer>
        <Announcement />
        <Subheading>Comenzi telefonice la numarul de telefon: {' '}
        <a href="tel:+40728434617" style={{ textDecoration: 'none', color: '#000' }}>
            0728434617
          </a>
        </Subheading>
      </HomeContent>
    </HomeContainer>
  );
};

export default Home;
