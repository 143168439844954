import React from 'react';
import styled from 'styled-components';

const AnnouncementContainer = styled.div`
  background-color: #fff3e0; /* Portocaliu deschis */
  border-left: 4px solid #f57c00; /* Portocaliu */
  padding: 1rem;
  margin-bottom: 2rem;
  text-align: left;
`;

const AnnouncementHeading = styled.h3`
  font-size: 1.5rem;
  color: #f57c00; /* Portocaliu */
  margin-bottom: 0.5rem;
`;

const AnnouncementText = styled.p`
  font-size: 1rem;
  color: #333;
`;

const Announcement = () => {
  return (
    <AnnouncementContainer>
      <AnnouncementHeading>Anunț</AnnouncementHeading>
      <AnnouncementText>
        Angajăm persoane dornice de muncă. Programul este de o zi cu două
        libere. Salariul: 3500 lei
      </AnnouncementText>
    </AnnouncementContainer>
  );
};

export default Announcement;
